<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <!-- <v-card> -->

        <v-layout wrap justify-center py-6>
          <v-flex xs12 py-3 align-self-center text-center>
            <span class="kumbhMedium" style="font-size: 18px"
              >Employee Document</span
            >
          </v-flex>
          <v-flex xs12 text-center v-if="documentList.length < 1">
            <span> no data Found..!</span>
          </v-flex>
          <v-flex
            v-else
            xs12
            lg10
            align-self-center
            fill-height
            pa-4
            class="hidden-xs-only"
          >
            <v-layout
              wrap
              class="kumbhSemibold"
              style="background-color: #005f32;color:#ffffff"
              pa-3
            >
              <v-flex sm4 md4 lg4>
                <span>TITLE</span>
              </v-flex>
              <v-flex sm4 md4 lg4>
                <span>DESCRIPTION</span>
              </v-flex>
              <v-flex sm4 md4 lg4>
                <span>DETAILS</span>
              </v-flex>
              <v-flex xs12>
                <v-spacer></v-spacer>
              </v-flex>
            </v-layout>
            <v-layout wrap style="font-size: 15px" class="kumbhRegular cblack">
              <v-flex xs12 py-4 v-for="(item, i) in documentList" :key="i">
                <v-layout wrap text-left justify-center>
                  <v-flex sm4 md4 lg4 pr-3 text-center align-self-center>
                    <!-- <a :href="'/docInfo?id=' + item._id" >
                      <span class="kumbhSemibold cuttext"> {{ item.title }}</span>
                    </a> -->
                     
                      <router-link :to="'/docInfo?id=' + item._id">
                        <span class="kumbhSemibold">{{ item.title }}</span>
                      </router-link>

                  </v-flex>
                  <v-flex sm4 md4 lg4 pr-2 text-center align-self-center>
                    <span class="cuttext" v-html="item.description.trim()">
                    </span>
                  </v-flex>
                  <v-flex sm4 md4 lg4 align-self-center text-center>
                    <!-- <v-layout wrap justify-center>
                      <v-flex xs12 text-center> -->
                    <!-- <v-btn plain text class="titles">Apply Now</v-btn> -->
                    <!-- <a :href="'/docInfo?id=' + item._id">
                      <v-btn tile dark color="blue" x-small>
                        <span class="kumbhRegular">View</span>
                      </v-btn>
                    </a> -->
                  
                       <router-link :to="'/YourDocInfo?id=' + item._id">
                        <v-btn tile dark color="blue" x-small>
                          <span class="kumbhRegular">View</span>
                        </v-btn>
                      </router-link>

                      
                        <!-- <v-btn tile dark color="blue" x-small>
                          <span class="kumbhRegular">View</span>
                        </v-btn> -->
           


                    <!-- </v-flex>
                    </v-layout> -->
                  </v-flex>
                  <v-flex xs12 py-4 align-self-center>
                    <div style="border-bottom: 1px solid #828282"></div>
                    <!-- <v-divider style="border:1px solid #828282"></v-divider> -->
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 v-if="documentList.length > 1" class="hidden-sm-and-up">
            <v-layout wrap>
              <v-flex xs12 pa-2 v-for="(items, t) in documentList" :key="t">
                <v-card outlined>
                  <v-layout wrap>
                    <v-flex xs12 pa-2>
                      <!-- <a :href="baseURL+'/docInfo?id=' + items._id">
                        <span class="kumbhSemibold">{{ items.title }}</span>
                      </a> -->
            
                         <router-link :to="'/docInfo?id=' + item._id">
                        <span class="kumbhSemibold">{{ items.title }}</span>
                      </router-link>

                    </v-flex>
                    <v-flex xs12 pa-2>
                      <span
                        class="kumbhRegular cuttext"
                        v-html="items.description"
                      ></span>
                    </v-flex>
                    <v-flex xs12 pa-2>
                      <!-- <a :href="baseURL+'/docInfo?id=' + items._id">
                        <v-btn tile dark color="blue" x-small>
                          <span class="kumbhRegular">View</span>
                        </v-btn>
                      </a> -->
              
                           <router-link :to="'/docInfo?id=' + item._id">
                        <v-btn tile dark color="blue" x-small>
                          <span class="kumbhRegular">View</span>
                        </v-btn>
                      </router-link>

                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      documentList: [],
      title: null,
      documentFile1: [],
      documentFile: null,
      documentID: null,
      docsdata: new FormData(),
      g: null,
      //end
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      content: "",
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },

      formDataCover: new FormData(),
      flagg: true,
    };
  },
  components: {
    // ImageComp,
  },
  mounted() {
    this.getData();
    // this.getCategory()
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    // jobCategory() {
    //   this.getCategory()
    // }
  },

  //   created() {
  //     this.initialize();
  //   },

  methods: {
    addDocument() {
      if (this.documentFile1.length > 0) {
        this.appLoading = true;
        axios({
          url: "/admin/add/edit/document",
          method: "post",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            title: this.title,
            description: this.content,
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.documentID = response.data.data._id;
            console.log();
            if (this.documentID != null) {
              this.docsupload();
            } else {
              alert("document updated");
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      } else {
        this.showsnackbar = true;
        this.msg = "Please Select Document ";
      }
    },
    docsupload() {
      for (var i = 0; i < this.documentFile1.length; i++) {
        this.docsdata.append("document", this.documentFile1[i]);
      }
      this.docsdata.append("id", this.documentID);

      this.appLoading = true;
      axios({
        url: "/admin/upload/multipledoc",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: this.docsdata,
      })
        .then((response) => {
          this.appLoading = false;
          this.dialog = false;
          this.documentFile1 = [];
          this.docsdata = new FormData();
          (this.title = null),
            (this.content = null),
            (this.showsnackbar = true);
          this.getData();
          this.msg = response.data.msg;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    browseCover(event) {
      var got = event.target.files;
      if (got.length < 5) {
        for (let i = 0; i < got.length; i++) {
          this.documentFile1.push(got[i]);
        }
        console.log("file isss", this.documentFile1);
      } else {
        this.msg = "Maximum 3 can be uploaded";
        this.showSnackBar = true;
      }
    },

    browseCover1(event) {
      var got = event.target.files[0];
      console.log("result is", got);
      this.documentFile = got;

      //   if (got.type == "application/pdf") {
      //     this.coverFile = got;
      //   } else {
      //     var img;
      //     img = new Image();
      //     img.src = window.URL.createObjectURL(event.target.files[0]);
      //     var ty = event.target.files[0];
      //     console.log(ty);
      //     if (ty.size > 3145728) {
      //       document.getElementById("file1").value = [];
      //       this.msg = "File size Should be less than 3MB";
      //       this.showSnackBar = true;
      //       return;
      //     } else {
      //       this.coverFile = got;
      //     }
      //   }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/employee/download/documents",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.documentList = response.data.data;
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err());
        });
    },
    deleteItem(val) {
      this.dialogDelete = false;
      this.appLoading = true;
      axios({
        url: "/admin/remove/document",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: val._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.getData();
            this.get;
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err());
        });
    },
    editcat(items) {
      this.editdialog = true;
      this.editingitem = items;
    },
    deletePDF(name, id) {
      this.appLoading = true;
      axios({
        url: "/admin/remove/uploaddocument",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          document: name,
          id: id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.dialog = false;

          this.showsnackbar = true;
          this.getData();
          this.msg = response.data.msg;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    edit(id) {
      this.appLoading = true;
      axios({
        url: "/admin/add/edit/document",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: id,
          title: this.editingitem.title,
          description: this.editingitem.content,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.documentID = response.data.data._id;
          this.editdialog = false;
          if (this.documentID != null) {
            this.docsupload();
          } else {
            alert("document updated");
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.cuttext {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
